import React, { useState } from "react";
import { decodeAkamaiV3 } from "../decoders/akamai/v3";

const AkamaiV3Decoder: React.FC = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const [submittedValue, setSubmittedValue] = useState<string>("");
  const [decodeId, setDecodeId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true); // Start spinner
    try {
      const decoded = await decodeAkamaiV3(inputValue);
      const parsed = JSON.parse(decoded[0])
        ? JSON.parse(decoded[0])
        : decoded[0];
      setDecodeId(decoded[1]);
      setSubmittedValue(JSON.stringify(parsed, null, 2));
    } catch (error) {
      console.error("Error decoding:", error);
    } finally {
      setLoading(false); // Stop spinner
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftView}>
        <form
          onSubmit={handleSubmit}
          style={styles.form as React.CSSProperties}
        >
          <textarea
            style={styles.textarea}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter your text here..."
          />
          <button type="submit" style={styles.button}>
            Submit
          </button>
        </form>
        {loading ? (
          <div className="spinner">Loading...</div> // Show spinner when loading
        ) : (
          ""
        )}
      </div>
      <div style={styles.rightView}>
        <p>Decode Id: {decodeId}</p>
        <div style={styles.outputBox}>
          {submittedValue ? (
            <p>{submittedValue}</p>
          ) : (
            <p style={{ color: "#777" }}>
              Your output will be displayed here...
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    height: "100vh",
    backgroundColor: "black",
  },
  leftView: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#1a1a1a",
  },
  rightView: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#333",
    color: "white",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "400px",
  },
  textarea: {
    flex: 1,
    padding: "20px",
    width: "100%",
    maxWidth: "400px",
    minHeight: "75vh",
    maxHeight: "80vh",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "none",
    fontSize: "16px",
  },
  button: {
    padding: "10px",
    backgroundColor: "#4caf50",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  },
  outputBox: {
    border: "1px solid #ccc",
    padding: "20px",
    width: "100%",
    maxWidth: "45vw",
    minHeight: "80vh",
    maxHeight: "80vh",
    backgroundColor: "#222",
    borderRadius: "5px",
    justifyContent: 'left',
    textAlign:'left' as 'left',
    fontSize: 12,
    display: "flex",
    overflow: "auto", // Allows scrolling if content exceeds the box size
    wordBreak: "break-word" as "break-word", // Type assertion to satisfy TypeScript
    whiteSpace: "pre-wrap" as "pre-wrap", // Also ensures TypeScript compatibility
  },
};

export default AkamaiV3Decoder;
