import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "./App.css";
import AkamaiV3Decoder from "./akamaiv3";
import PerimeterXDecoder from "./perimeterX/cookie";

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/akamai/v3">Akamai V3</Link>
              </li>
              <li>
                <Link to="/perimeterX/cookie">PerimeterX Cookie</Link>
              </li>
            </ul>
          </nav>
        </header>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/akamai/v3" element={<AkamaiV3Decoder />} />
          <Route path="/perimeterX/cookie" element={<PerimeterXDecoder />} />
        </Routes>
      </div>
    </Router>
  );
}

const Home: React.FC = () => {
  return (
    <div>
      <h1>Kage Apis Decoders</h1>
    </div>
  );
};

export default App;
