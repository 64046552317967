export async function decodeAkamaiV3(sensor_data: string) {
  const relevantStuff = sensor_data.split(";").slice(7).join(";");
  const ver = sensor_data.split(";")[5];

  const hashKey = sensor_data.split(";")[4];

  const unscrambleData = (data: any, initialT9v: number) => {
    const Rlv = data;
    let T9v = initialT9v;
    // biome-ignore lint/style/useSingleVarDeclarator: <explanation>
    let HZv, HGv, flv, blv;

    const bMv = ":";
    const Hlv = Rlv.split(bMv);

    // Create an array to store the intermediate T9v values
    const T9vValues = [];

    // Generate the same T9v values as in scrambleData
    for (blv = 0; blv < Hlv.length; blv++) {
      T9vValues.push(T9v);
      T9v *= 65793;
      T9v &= 4294967295;
      T9v += 4282663;
      T9v &= 8388607;
      T9v *= 65793;
      T9v &= 4294967295;
      T9v += 4282663;
      T9v &= 8388607;
    }

    // Iterate in reverse order to undo the scrambling
    for (blv = Hlv.length - 1; blv >= 0; blv--) {
      T9v = T9vValues[blv];
      HZv = (T9v >> 8) % Hlv.length;
      T9v *= 65793;
      T9v &= 4294967295;
      T9v += 4282663;
      T9v &= 8388607;
      HGv = (T9v >> 8) % Hlv.length;
      flv = Hlv[HGv];
      Hlv[HGv] = Hlv[HZv];
      Hlv[HZv] = flv;
    }

    return Hlv.join(bMv);
  };

  const R6 = [
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 0, 1, -1, 2, 3, 4, 5,
    -1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
    25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
    44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, -1, 58, 59, 60, 61,
    62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80,
    81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91,
  ];

  const QV =
    " !#$%&()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[]^_`abcdefghijklmnopqrstuvwxyz{|}~";

  const unhashData = (hashedData: string) => {
    const LL = hashedData;
    let lH = Number(hashKey);

    let originalData = "";

    for (let M9 = 0; M9 < LL.length; M9++) {
      let s6 = LL.charAt(M9);
      const L9 = lH >> 8;

      lH *= 65793;
      lH &= 4294967295;
      lH += 4282663;
      lH &= 8388607;

      let TO = QV.indexOf(s6);

      if (TO >= 0) {
        const IS = L9 % QV.length;
        TO = (TO - IS + QV.length) % QV.length;
        s6 = String.fromCharCode(R6.indexOf(TO));
      }

      originalData += s6;
    }

    return originalData;
  };

  const unhashed = unhashData(relevantStuff);

  function isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function findCorrectT9v(data: string, expectedStart: string) {
    for (let T9v = 1000000; T9v <= 9999999; T9v++) {
      if (T9v % 1000000 === 0) {
        console.log("Finding T9v:", T9v);
      }

      const result = unscrambleData(data, T9v);

      if (result.startsWith(expectedStart) && isJson(result)) {
        return [result, T9v];
      }
    }

    return [];
  }

  const [unscrambled, T9v] = findCorrectT9v(unhashed, `{"ver":"${ver}",`);
  return [unscrambled, T9v];
}
