export async function decodePerimeterXCookies(data: string): Promise<[any, any]> {
  function decodeJoe(t: any, n: string) {
    const a = base64Decode(t);
    const i = extractDigits(n);
    return xorCipher(a, parseInt(i, 10) % 128).split("~~~~");
  }

  function base64Decode(t: string) {
    const base64chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    let decoded = "";

    t = t.replace(/[^A-Za-z0-9+/]/g, "");

    for (let i = 0; i < t.length; i += 4) {
      const b = [
        base64chars.indexOf(t.charAt(i)),
        base64chars.indexOf(t.charAt(i + 1)),
        base64chars.indexOf(t.charAt(i + 2)),
        base64chars.indexOf(t.charAt(i + 3)),
      ];

      const decodedChars = [
        (b[0] << 2) | (b[1] >> 4),
        ((b[1] & 15) << 4) | (b[2] >> 2),
        ((b[2] & 3) << 6) | b[3],
      ];

      for (let j = 0; j < decodedChars.length; j++) {
        if (b[j + 2] === 64) break; // Skip padding characters
        decoded += String.fromCharCode(decodedChars[j]);
      }
    }

    return decoded;
  }

  function extractDigits(t: string) {
    let digits = "";
    for (let i = 0; i < t.length; i++) {
      const charCode = t.charCodeAt(i);
      if (charCode >= 48 && charCode <= 57) {
        digits += t[i];
      }
    }
    return digits;
  }

  function xorCipher(t: string, n: number) {
    let ciphered = "";
    for (let i = 0; i < t.length; i++) {
      ciphered += String.fromCharCode(n ^ t.charCodeAt(i));
    }
    return ciphered;
  }

  let decodedData = "";

  let loops = 0;
  while (loops < 150) {
    const decoded = decodeJoe(data, String(loops));
    console.log(decoded);
    try {
      decodedData = JSON.stringify(decoded);
      let shouldBreak = false;
      for (const resp of decoded) {
        if (
          resp.includes("|_px3|") ||
          resp.includes("|ccc:") ||
          resp.includes("|cookie_names|") ||
          resp.includes("|fp|") ||
          resp.includes("|_pxde|")
        )
          shouldBreak = true;
      }
      if (shouldBreak) break;
      else loops = loops + 1;
    } catch {
      loops = loops + 1;
    }
  }
  return [decodedData, loops];
}
